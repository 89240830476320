export const RouteNames = {
  LOGIN_ROUTE: 'auth/login',
  AUTH_ROUTE: 'auth',
  SHOP_ROUTE: 'shop',
  FINANCE_ROUTE: 'finance',
  CLIENTS_ROUTE: 'clients',
  CONTENT_ROUTE: 'content',
  CONTENT_BUILDING_BLOCKS_ROUTE: 'content/building-blocks',
  CONTENT_PLACEHOLDERS_ROUTE: '/content/config/custom-placeholders',
  CONTENT_AUDIO_SESSIONS_ROUTE: '/content/audio-sessions',
  CONTENT_SMART_PROGRAMS_ROUTE: '/content/config/smart-programs',
  CONTENT_SERIES_ROUTE: '/content/series',
  CONTENT_PROGRAMS_ROUTE: '/content/programs',
  DASHBOARD_ROUTE: 'dashboard',
  USER_ACCOUNT_ROUTE: 'user-account',
}